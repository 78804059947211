import { ThemeProvider } from "@mui/material";
import { baseTheme } from "./assets/global/Theme-variable";
import { useRoutes } from "react-router-dom";
import privateRoutes from "./Routes/privateRoutes";
import publicRoutes from "./Routes/publicRoutes";
import { ToastProvider } from "use-toast-mui";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

function App() {
  const theme = baseTheme;
  const privateRouting = useRoutes(privateRoutes);
  const publicRouting = useRoutes(publicRoutes);
  const isAuthenticated = Boolean(window.localStorage.getItem("access_token"));

  return (
    <ThemeProvider theme={theme}>
      <ToastProvider>{isAuthenticated ? privateRouting : publicRouting}</ToastProvider>
    </ThemeProvider>
  );
  
}

export default App;
