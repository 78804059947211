import { callApi } from "./setup";

const baseUrl = "events";

  export const GetEvents = (params = {}) => {
    if (!params.search) params.search = 'all';
    return callApi(baseUrl, "GET", params, {},true,true);
  };
  
  export const UpdateStatusEvent = (params, body) => {
    return callApi(baseUrl, "PUT", params, body, true, true);
  };
  
  export const DeleteEvent = (params) => {
    return callApi(`${baseUrl}/${params}`, "GET", {}, {},true,true);
  };
  
  export const AddEvent = (body) => {
    return callApi(baseUrl, "POST", {}, body, true, true);
  };