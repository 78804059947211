import { lazy } from "react";
import { Navigate } from "react-router-dom";
import * as PATH from "./paths";

const FullLayout = lazy(() => import("../layouts/FullLayout/FullLayout.js"));
const Requests = lazy(() => import("../screens/Requests/Requests.js"));
const Users = lazy(() => import("../screens/Users/Users.js"));
const UserDetail = lazy(() => import("../screens/Users/UserDetail.js"));
const RequestDetail = lazy(() => import("../screens/Requests/RequestDetail.js"));
const RequestForm = lazy(() => import("../screens/Requests/RequestForm.js"));
const Badges = lazy(() => import("../screens/Badges/Badges.js"));
const Login = lazy(() => import("../screens/Login/Login.js"));
const Dashboard = lazy(() => import("../screens/Dashboard/Dashboard.js"));
const Events = lazy(() => import("../screens/Events/Events.js"));

const privateRoutes = [
  {
    path: "/",
    children: [
      { path: "/", element: <Navigate to={PATH.LOGIN_PATH} /> },
      { path: PATH.LOGIN_PATH, exact: true, element: <Login /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
  {
    path: PATH.PANEL_PATH,
    element: <FullLayout />,
    children: [
      { path: PATH.PANEL_PATH, element: <Navigate to={PATH.DASHBOARD_PATH} /> },
      {
        path: PATH.DASHBOARD_PATH,
        exact: true,
        element: <Dashboard />,
      },
      {
        path: PATH.REQUESTS_PATH,
        exact: true,
        element: <Requests />,
      },
      {
        path: PATH.REQUEST_DETAIL_PATH,
        element: <RequestDetail />,
      },
      {
        path: PATH.REQUEST_DETAIL_EDIT_PATH,
        exact: true,
        element: <RequestForm />,
      },
      {
        path: PATH.USERS_PATH,
        exact: true,
        element: <Users />,
      },
      {
        path: PATH.USER_DETAIL_PATH,
        exact: true,
        element: <UserDetail />,
      },
      {
        path: PATH.BADGES_PATH,
        exact: true,
        element: <Badges />,
      },
      {
        path: PATH.EVENTS_PATH,
        exact: true,
        element: <Events />,
      },
    ],
  },
];

export default privateRoutes;
