import { callApi } from "./setup";

const baseUrl = "users";

export const GetUsers = (params = {}) => {
  return callApi(baseUrl, "GET", params, {});
};

export const GetUserStatistics = (params = {}) => {
  return callApi(baseUrl + "/statistics", "GET", params, {});
};

export const GetBloodGroupSummary = (params = {}) => {
  return callApi(baseUrl + "/summary", "GET", params, {});
};

export const GetUserDetail = (userId) => {
  return callApi(`${baseUrl}/${userId}`, "GET", {}, {});
};

export const SendNotificationToUser = (body) => {
  return callApi(`${baseUrl}/send-notification-to-device`, "POST", {}, body);
};

export const SendNotificationToAllUsers = (body) => {
  return callApi(`${baseUrl}/send-global-notification`, "POST", {}, body);
};
