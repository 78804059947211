import React, { useEffect, useState } from "react";
import "./login.css";
import login_img from "../../assets/image/login-img.png";
import { useNavigate } from "react-router-dom";
import { AdminLogin } from "../../axios";
import logo from "../../assets/image/instabloodLogo.svg";
import { useAuthStore } from "../../store/auth";
import { Alert, Button, CircularProgress } from "@mui/material";
import { FormPassword, FormTextInput } from "../../components/form-components";
import { useForm } from "react-hook-form";
import { useToast } from "use-toast-mui";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { emailMessage, requiredMessage, stringMessage } from "../../config";

const schema = yup.object({
  email: yup.string(stringMessage).email(emailMessage).required(requiredMessage),
  password: yup.string(stringMessage).required(requiredMessage),
});

function Login() {
  const navigate = useNavigate();
  const toast = useToast();
  const [errMessage, setErrMessage] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const setAuthenticated = useAuthStore((state) => state.setAuthenticated);

  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const isAuthenticated = Boolean(window.localStorage.getItem("access_token"));
    if (isAuthenticated) navigate("/panel");
  }, []);

  const onSubmit = (newData) => {
    setFormLoading(true);
    const formData = {
      user: {
        email: newData.email,
        password: newData.password,
      },
    };
    AdminLogin(formData)
      .then((res) => {
        toast.success("Successfully logged in!");
        setFormLoading(false);
        setAuthenticated(res.data.token.access_token);
        window.localStorage.setItem("access_token", res.data.token.access_token);
        navigate("/panel");
      })
      .catch((e) => {
        setFormLoading(false);
        setErrMessage(e?.response?.data?.message || "Error occurred");
        console.log("error", e);
      });
  };

  return (
    <>
      <div className="login-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <img src={login_img} alt="backgroundimage" />
            </div>
            <div className="col-md-5">
              <div className="card-login">
                <div className="instaBlood_logo">
                  <img src={logo} alt="InstabloodLogo" />
                </div>
                <h3>Sign In</h3>
                {errMessage && (
                  <Alert className="mb-2" severity="error">
                    {errMessage}
                  </Alert>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-2">
                    <FormTextInput name="email" control={control} label="Email" />
                  </div>
                  <div className="mb-5">
                    <FormPassword name="password" control={control} label="Password" />
                  </div>
                  <Button
                    fullWidth
                    type="submit"
                    size="large"
                    disabled={formLoading}
                    startIcon={formLoading ? <CircularProgress size={20} /> : <></>}
                    variant="contained"
                  >
                    SUBMIT
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
