import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

export const FormSelect = ({ name, control, label, className, options = [] }) => {
  const generateSingleOptions = () => {
    return options?.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  return (
    <div className={className}>
      <FormControl className={className}>
        <InputLabel>{label}</InputLabel>
        <Controller
          defaultValue={""}
          render={({ field: { onChange, value } }) => (
            <Select onChange={onChange} value={value}>
              {generateSingleOptions()}
            </Select>
          )}
          control={control}
          name={name}
        />
      </FormControl>
    </div>
  );
};
