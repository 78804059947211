import axios from "axios";
import Swal from "sweetalert2";

const callApi = (url, method, params, body, isAuth = true, isEvent = false) => {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const EVENT_URL = process.env.REACT_APP_BASE_API_URL;
  let axiosReq;
  if (isAuth) {
    const user = window.localStorage.getItem("access_token");
    axiosReq = axios.create({
      baseURL: isEvent ? EVENT_URL : BASE_URL,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
  } else {
    axiosReq = axios.create({
      baseURL: BASE_URL,
    });
  }

  axiosReq.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosReq.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response) {
        if (
          error?.response?.status === 401 &&
          error?.response?.data.message !== "USER_DOES_NOT_EXISTS"
        ) {
          Swal.fire("Session Expired").then((result) => {
            localStorage.clear();
            window.location.href = "/";
          });
        }
        console.log("issue in axios while response an api in request", error.response.config.url);
      }
      return Promise.reject(error);
    },
  );

  if (Object.keys(params).length) {
    url += "?";
    for (let i = 0; i < Object.keys(params).length; i++) {
      const key = Object.keys(params)[i];
      const value = params[key];
      url += `${key}=${value}`;
      if (i !== Object.keys(params).length - 1) url += "&";
    }
  }

  switch (method) {
    case "GET":
      return axiosReq.get(url);
    case "PUT":
      return axiosReq.put(url, body);
    case "POST":
      return axiosReq.post(url, body);
    case "DELETE":
      return axiosReq.delete(url);
    default:
      return;
  }
};

export { callApi };
