export * from "./validationMessage";

export const DEFAULT_PAGE = 1;
export const DEFAULT_SIZE = 10;

export const GOOGLE_MAPS_API_KEY = "AIzaSyAgz7aMJJdcGfAFJwMcdfLqGYE-omv5k00";

export const COLORS = [
  "#F8DE22",
  "#6F61C0",
  "#C70039",
  "#071952",
  "#7C73C0",
  "#00DFA2",
  "#0079FF",
  "#865DFF",
];

export const BloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];

export const BloodGroupFilter = ["#/panel/users", "#/panel/requests"];
export const CountryFilter = ["#/panel/users", "#/panel/requests"];
