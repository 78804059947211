import Login from "../screens/Login/Login";
import { Navigate } from "react-router-dom";
import { LOGIN_PATH } from "./paths";

const publicRoutes = [
  {
    path: "/",
    children: [
      { path: "/", element: <Navigate to={LOGIN_PATH} /> },
      { path: LOGIN_PATH, exact: true, element: <Login /> },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
  },
];

export default publicRoutes;
