import { callApi } from "./setup";

const baseUrl = "requests";

export const GetBloodRequests = (params) => {
  return callApi(baseUrl, "GET", params, {});
};

export const GetBloodRequestStatistics = (params) => {
  return callApi(baseUrl + '/statistics', "GET", params, {});
};

export const GetBloodGroups = (params) => {
  return callApi("blood-groups", "GET", params, {});
};

export const GetBloodRequestDetail = (id) => {
  return callApi(`${baseUrl}/${id}`, "GET", {}, {});
};

export const UpdateBloodRequestDetail = (id, body) => {
  return callApi(`${baseUrl}/${id}/edit`, "PUT", {}, body);
};

export const GetRequestDonorList = (params) => {
  return callApi(
    `${baseUrl}/${params.requestId}/donor-list`,
    "GET",
    { page: params.page, size: params.size },
    {},
  );
};

export const UpdateRequestStatus = (body) => {
  return callApi(`${baseUrl}/change-status`, "PUT", {}, body);
};

export const UpdateRequestDonorStatus = (body) => {
  return callApi(`${baseUrl}/blood-donor/change-status`, "PUT", {}, body);
};

export const SendNotificationToDevice = (body) => {
  return callApi(`${baseUrl}/send-notification-to-device`, "POST", {}, body);
};

export const SendNotificationToEmail = (body) => {
  return callApi(`${baseUrl}/send-notification-to-email`, "POST", {}, body);
};

export const GetRequestSummary = (params = {}) => {
  return callApi(baseUrl + "/summary", "GET", params, {});
};
