export const LOGIN_PATH = "/login";
export const PANEL_PATH = "/panel";

// DASHBOARD
export const DASHBOARD_PATH = `${PANEL_PATH}/dashboard`;
// REQUESTS
export const REQUESTS_PATH = `${PANEL_PATH}/requests`;
export const REQUEST_DETAIL_PATH = `${PANEL_PATH}/requests/:id`;
export const REQUEST_DETAIL_EDIT_PATH = `${PANEL_PATH}/requests/:id/edit`;
// USERS
export const USERS_PATH = `${PANEL_PATH}/users`;
export const USER_DETAIL_PATH = `${PANEL_PATH}/users/:id`;
export const USER_DETAIL_EDIT_PATH = `${PANEL_PATH}/users/:id/edit`;
// BADGES
export const BADGES_PATH = `${PANEL_PATH}/badges`;
export const BADGE_DETAIL_PATH = `${PANEL_PATH}/badges/:id`;
export const BADGE_DETAIL_EDIT_PATH = `${PANEL_PATH}/badges/:id/edit`;
// Events
export const EVENTS_PATH = `${PANEL_PATH}/events`;
export const EVENTS_DETAIL_PATH = `${PANEL_PATH}/events/:id`;

export const DEFAULT_PATH = DASHBOARD_PATH;
