import { create } from "zustand";

const useAuthStore = create((set) => ({
  isAuthenticated: false,
  accessToken: "",
  setAuthenticated: (token) => set({ isAuthenticated: true, accessToken: token }),
  unsetAuthenticated: () => set({ isAuthenticated: false, token: "" }),
}));

export { useAuthStore };
