import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Controller } from "react-hook-form";

export const FormDatePicker = ({ name, control, label }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DateTimePicker
            format="DD/MM/YYYY - h:MM A"
            className="w-100"
            label={label}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </LocalizationProvider>
  );
};
